import React, {useState, useEffect, useRef} from 'react'
import {useRouter} from 'next/router'
import {isEmpty} from 'lodash'

import {createCtx} from '../utils/context'

const carouselContext = createCtx()

const Provider = carouselContext[1]
export const useUTMContext = carouselContext[0]

export const UTMContextProvider = ({children}) => {
  const {query} = useRouter()

  const [data, setData] = useState({})
  const initialRef = useRef(false)

  useEffect(() => {
    if (initialRef.current) return

    if (!isEmpty(query)) {
      initialRef.current = true

      setData({
        id: query.utm_id,
        term: query.utm_term,
        source: query.utm_source,
        medium: query.utm_medium,
        campaign: query.utm_campaign,
        content: query.utm_content,
      })
    }
  }, [query])

  return <Provider value={{data}}>{children}</Provider>
}
