import {useState, useCallback} from 'react'

export function useAsync(asyncFunction) {
  const [status, setStatus] = useState('idle')
  const [data, setData] = useState(null)
  const [error, setError] = useState(null)

  const isLoading = status === 'loading'
  const isSuccess = status === 'success'

  const run = useCallback(
    async (params) => {
      setStatus('loading')
      setData(null)
      setError(null)

      try {
        const res = await asyncFunction(params)
        setData(res)
        setStatus('success')
        return res
      } catch (err) {
        setError(err)
        setStatus('error')
      }
    },
    [asyncFunction]
  )

  const reset = useCallback(() => {
    setStatus('idle')
    setData(null)
    setError(null)
  }, [])

  return {run, status, isLoading, isSuccess, data, error, reset}
}
