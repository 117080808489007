import React from 'react'
const IconCaretDown = (props) => (
  <svg
    width='29'
    height='14'
    fill='currentColor'
    viewBox='0 0 29 14'
    {...props}
  >
    <path d='M.15 0L14.5 14.35 28.85 0z' />
  </svg>
)

const IconArrowRight = (props) => (
  <svg
    width='35'
    height='16'
    fill='currentColor'
    viewBox='0 0 35 16'
    {...props}
  >
    <path d='M26.996 0a.727.727 0 00-.535 1.238l5.816 6.039H.738a.726.726 0 100 1.454h31.54l-5.817 6.038a.728.728 0 001.054 1.005L35 8.004 27.515.233A.735.735 0 0026.996 0z' />
  </svg>
)

const IconVector = (props) => (
  <svg
    width='167px'
    height='101px'
    viewBox='0 0 167 101'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M38.0599 50.8024H60.056V99H2V58.332C2 38.4534 7.7694 24.1906 19.3082 15.5585C30.847 6.92264 47.3121 2.40561 68.7123 2L73.4002 20.9789C47.9124 23.1913 36.1309 33.1325 38.0555 50.8024H38.0599ZM129.66 50.8024H151.656V99H93.5954V58.332C93.5954 38.4534 99.3692 24.1906 110.908 15.5585C122.447 6.92633 138.912 2.40561 160.312 2L165 20.9789C139.512 23.1913 127.731 33.1325 129.655 50.8024H129.66Z'
      stroke='url(#paint0_linear_478_39040)'
      strokeWidth='4'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <defs>
      <linearGradient
        id='paint0_linear_478_39040'
        x1='66.7269'
        y1='89.0513'
        x2='151.148'
        y2='90.6805'
        gradientUnits='userSpaceOnUse'
      >
        <stop stopColor='#47AA91' />
        <stop offset='1' stopColor='#6BD0D3' />
      </linearGradient>
    </defs>
  </svg>
)

export {IconArrowRight, IconVector, IconCaretDown}
