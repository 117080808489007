import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import {motion, AnimatePresence, useAnimation} from 'framer-motion'
import classNames from 'classnames'

export const RealWorld = () => {
  const [[page, direction], setPage] = useState([0, 0])
  const controls = useAnimation()

  const variants = {
    enter: (direction) => {
      return {
        x: direction > 0 ? 100 : -100,
        opacity: 0,
      }
    },
    center: {
      zIndex: 1,
      x: 0,
      opacity: 1,
    },
    exit: (direction) => {
      return {
        zIndex: 0,
        x: direction > 0 ? -100 : 100,
        opacity: 0,
      }
    },
  }

  useEffect(() => {
    const element = document.querySelector('.CustomAI__realWorld')
    const onScroll = () => {
      const elementTop = element.getBoundingClientRect().top
      const windowHeight = window.innerHeight

      if (elementTop < windowHeight * 0.75) {
        controls.start({opacity: 1, y: 0})
      } else {
        controls.start({opacity: 0, y: 50})
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [controls])

  const data = [
    {
      title: 'Manufacturing',
      key: 'manufacturing',
      img: '',
      content: [
        {
          label: 'Supply Chain Management',
          des: 'Optimize inventory management, demand forecasting, and logistics planning',
        },
        {
          label: 'Predictive Maintenance',
          des: 'Anticipate equipment breakdown or need for maintenance from IoT devices’ data',
        },
        {
          label: 'Safety Inspection',
          des: 'Automate inspection of corporate safety compliance with predetermined standards',
        },
      ],
    },
    {
      title: 'Retail & Hospitality',
      key: 'retail',
      img: '',
      content: [
        {
          label: 'Customer Segmentation',
          des: 'Categorize customers based on common characteristics to better target marketing efforts',
        },
        {
          label: 'Product Propensity',
          des: 'Suggest products to upsell or cross sell based on propensity to purchase from historical transactions',
        },
        {
          label: 'Dynamic Pricing',
          des: 'Analyze market trends and customer behavior to determine optimal pricing strategies',
        },
      ],
    },
    {
      title: 'Financial Services',
      key: 'financial',
      img: '',
      content: [
        {
          label: 'Credit Scoring and Underwriting',
          des: 'Automate underwriting and assess creditworthiness by analyzing credit history and data',
        },
        {
          label: 'Churn Prevention',
          des: 'Identify customers who are at risk of leaving, and plan targeted actions to retain them',
        },
        {
          label: 'Fraud Detection',
          des: 'Identify anomalies or inconsistencies in data that may suggest fraudulent activities',
        },
      ],
    },
    {
      title: 'Agriculture',
      key: 'agriculture',
      img: '',
      content: [
        {
          label: 'Crop Monitoring and Yield Prediction',
          des: 'Monitor crop health and predict crop yield by analyzing satellite imagery and sensor data',
        },
        {
          label: 'Pest and Disease Detection',
          des: 'Analyze crop images and data to detect pests, diseases, and weed growth',
        },
        {
          label: 'Precision Agriculture',
          des: 'Optimize farming practices by analyzing weather conditions, soil moisture, and yield data',
        },
      ],
    },
    {
      title: 'Marketing & Media',
      key: 'marketing',
      img: '',
      content: [
        {
          label: 'Sentiment Analysis',
          des: 'Analyze customer feedback and overall emotional tone to determine follow up actions',
        },
        {
          label: 'Targeted Marketing',
          des: 'Personalized marketing messages according to the needs of specific customer segment',
        },
        {
          label: 'Social Trending',
          des: 'Analyze social media activities and online mentions to identify popular trends and topics',
        },
      ],
    },
    {
      title: 'Logistics',
      key: 'logistics',
      img: '',
      content: [
        {
          label: 'Route Optimization',
          des: 'Optimize transportation routes and schedules to reduce costs and improve efficiency',
        },
        {
          label: 'Demand Planning',
          des: 'Forecast demands from historical data, market trends, and external factors for effective resource allocation',
        },
        {
          label: 'Warehouse Management',
          des: 'Automate warehouse operations to optimize tasks like inventory tracking, packing, and storing',
        },
      ],
    },
  ]
  const tabs = [
    {title: 'Manufacturing', index: 0},
    {title: 'Retail & Hospitality', index: 1},
    {title: 'Financial Services', index: 2},
    {title: 'Agriculture', index: 3},
    {title: 'Marketing & Media', index: 4},
    {title: 'Logistics', index: 5},
  ]

  return (
    <motion.div
      className='CustomAI__realWorld'
      initial={{opacity: 0, y: 10}}
      animate={controls}
      transition={{duration: 0.1}}
    >
      <div className='CustomAI__realWorld__container'>
        <h1>
          Real-World AI
          <span>in Action</span>
        </h1>
        {/* tab mobile */}
        <div className='CustomAI__realWorld__container__tab--mobile'>
          <Tabs
            tabs={tabs}
            currentIndex={page}
            onChangeIndex={(index) => {
              const isNext = page < index ? 1 : -1
              setPage([index, isNext])
            }}
          />
        </div>
        {/* tab desktop */}
        <div className='CustomAI__realWorld__container__tab--desktop'>
          <Tabs
            tabs={tabs}
            currentIndex={page}
            onChangeIndex={(index) => {
              const isNext = page < index ? 1 : -1
              setPage([index, isNext])
            }}
          />
        </div>
        <div className='CustomAI__realWorld__container__content'>
          {/* @ts-ignore */}
          <div className='desktop'>
            {/* @ts-ignore */}
            <AnimatePresence initial={false} custom={direction} exitBeforeEnter>
              <motion.div
                className='cards'
                key={data[page].key}
                variants={variants}
                custom={direction}
                initial='enter'
                animate='center'
                exit='exit'
                transition={{
                  opacity: {duration: 0.6},
                }}
              >
                <div className='card'>
                  <img
                    src={`/images/custom-ai/${data[page].key}-${tabs[1].index}.jpg`}
                  />

                  <div className='card__content__list__label'>
                    <h3>{data[page].content[0].label}</h3>
                  </div>
                  <div className='card__content__list__description'>
                    <p>{data[page].content[0].des}</p>
                  </div>
                </div>
                <div className='card'>
                  <img
                    src={`/images/custom-ai/${data[page].key}-${tabs[2].index}.jpg`}
                  />

                  <div className='card__content__list__label'>
                    <h3>{data[page].content[1].label}</h3>
                  </div>
                  <div className='card__content__list__description'>
                    <p>{data[page].content[1].des}</p>
                  </div>
                </div>
                <div className='card'>
                  <img
                    src={`/images/custom-ai/${data[page].key}-${tabs[3].index}.jpg`}
                  />
                  <div className='card__content__list__label'>
                    <h3>{data[page].content[2].label}</h3>
                  </div>
                  <div className='card__content__list__description'>
                    <p>{data[page].content[2].des}</p>
                  </div>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
          <div className='mobile'>
            {/* @ts-ignore */}
            <AnimatePresence initial={false} custom={direction} exitBeforeEnter>
              <motion.div
                className='cards'
                key={data[page].key}
                variants={variants}
                custom={direction}
                initial='enter'
                animate='center'
                exit='exit'
                transition={{
                  opacity: {duration: 0.6},
                }}
              >
                <div className='card'>
                  <div className='card__img'>
                    <img
                      src={`/images/custom-ai/${data[page].key}-${tabs[1].index}.jpg`}
                    />
                  </div>

                  <div className='card__content__list__label'>
                    <h3>{data[page].content[0].label}</h3>
                  </div>
                  <div className='card__content__list__description'>
                    <p>{data[page].content[0].des}</p>
                  </div>
                </div>
                <div className='card'>
                  <img
                    src={`/images/custom-ai/${data[page].key}-${tabs[2].index}.jpg`}
                  />

                  <div className='card__content__list__label'>
                    <h3>{data[page].content[1].label}</h3>
                  </div>
                  <div className='card__content__list__description'>
                    <p>{data[page].content[1].des}</p>
                  </div>
                </div>
                <div className='card'>
                  <img
                    src={`/images/custom-ai/${data[page].key}-${tabs[3].index}.jpg`}
                  />
                  <div className='card__content__list__label'>
                    <h3>{data[page].content[2].label}</h3>
                  </div>
                  <div className='card__content__list__description'>
                    <p>{data[page].content[2].des}</p>
                  </div>
                </div>
              </motion.div>
            </AnimatePresence>
          </div>
        </div>
      </div>
    </motion.div>
  )
}

const Tabs = ({currentIndex, onChangeIndex, tabs}) => {
  return (
    <div className='CustomAI__realWorld__container__tabs'>
      {tabs.map((item) => {
        return (
          <motion.div
            className={classNames('item', {
              'item--active': currentIndex === item.index,
            })}
            onClick={() => onChangeIndex(item.index)}
            key={item.title}
          >
            <div className='item__content'>
              <p>{item.title}</p>
            </div>
          </motion.div>
        )
      })}
    </div>
  )
}

Tabs.propTypes = {
  currentIndex: PropTypes.number.isRequired,
  onChangeIndex: PropTypes.func.isRequired,
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      index: PropTypes.number.isRequired,
      title: PropTypes.string.isRequired,
    })
  ).isRequired,
}
