import React from 'react'
import Link from 'next/link'
import Image from 'next/image'
import {logEvent} from '@lib/gtm'
import {Form} from '@components/Form'

export const CustomAiFooter = () => {
  return (
    <div className='CustomAI__footer'>
      <div className='CustomAI__footer__container'>
        <div className='CustomAI__footer__container__info'>
          <div className='address'>
            <Link href='/'>
              <a
                className='Nav__logo-brand'
                title='datawow.co.th'
                onClick={() => {
                  logEvent({
                    ga: {
                      category: 'Homepage',
                      action: 'Click Homepage',
                    },
                  })
                }}
              >
                <Image
                  src='/images/logo-white.svg'
                  alt='datawow logo'
                  width='296px'
                  height='46px'
                />
              </a>
            </Link>
            <div>
              1778 Summer Hub Office, 6th Floor, <br /> Sukhumvit Road, Phra
              Khanong, <br />
              Khlong Toei, Bangkok 10110 <br /> THAILAND
            </div>
          </div>
          <div className='contact'>
            <a
              id='phone'
              href='tel:020245560'
              onClick={() => {
                logEvent({
                  ga: {
                    category: 'Phone',
                    action: 'Click Phone',
                    label: 'Footer',
                  },
                })
              }}
            >
              Tel: 02-024-5560
            </a>
            <strong>
              <a
                id='email'
                href='mailto:sales@datawow.io'
                onClick={() => {
                  logEvent({
                    ga: {
                      category: 'Email',
                      action: 'Click Email',
                      label: 'Footer',
                    },
                  })
                }}
              >
                sales@datawow.io
              </a>
            </strong>

            <iframe
              src='https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3876.072195349628!2d100.58990201483007!3d13.714077190371894!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x30e29feb33e83421%3A0xfe14120eb3d8af4f!2sData%20Wow!5e0!3m2!1sen!2sth!4v1585543617852!5m2!1sen!2sth'
              title='map'
              allowFullScreen
            />
          </div>
        </div>
        <div className='CustomAI__footer__container__form' id='contact'>
          <Form title='<h3>Contact Us</h3>' />
        </div>
      </div>
    </div>
  )
}
