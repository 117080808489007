import * as React from 'react'
import debounce from 'lodash/debounce'

export const useScrollInfo = () => {
  const [scrollInfo, setScrollInfo] = React.useState({
    currentScrollPos: 0,
    direction: '',
  })

  React.useEffect(() => {
    let prevScrollPos = window.pageYOffset

    const handler = () => {
      const currentScrollPos = window.pageYOffset
      const direction = prevScrollPos < currentScrollPos ? 'down' : 'up'
      prevScrollPos = currentScrollPos

      setScrollInfo({
        currentScrollPos,
        direction,
      })
    }

    const debouncedHandler = debounce(handler, 10, {
      leading: true,
    })

    handler()
    document.addEventListener('scroll', debouncedHandler)

    return () => {
      document.removeEventListener('scroll', debouncedHandler)
    }
  }, [])

  return scrollInfo
}
