import React, {useEffect} from 'react'

import {motion, useAnimation} from 'framer-motion'

import {logEvent} from '@lib/gtm'

import {IconArrowRight, IconVector} from '@components/icon'

export const About = ({animation, scrollTo}) => {
  const controls = useAnimation()
  useEffect(() => {
    const element = document.querySelector('.CustomAI__about')
    const onScroll = () => {
      const elementTop = element.getBoundingClientRect().top
      const windowHeight = window.innerHeight

      if (elementTop < windowHeight * 0.75) {
        controls.start({opacity: 1, y: 0})
      } else {
        controls.start({opacity: 0, y: 50})
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [controls])
  return (
    <motion.div
      className='CustomAI__about'
      initial={{opacity: 0, y: 10}}
      animate={controls}
      transition={{duration: 0.1}}
    >
      <div className='CustomAI__about__bg'>
        <img
          className='bg-1'
          src='/images/custom-ai/bg-small.svg'
          alt='custom-ai'
        />
        <img
          className='bg-2'
          src='/images/custom-ai/bg-big.svg'
          alt='custom-ai'
        />
      </div>
      <div className='CustomAI__about__container'>
        <div className='CustomAI__about__container__quote'>
          <IconVector />
          <hr className='line' />
        </div>
        <div className='CustomAI__about__container__content'>
          <div className='content-1'>
            <picture className='content-1__image'>
              <source
                srcSet='/images/custom-ai/ai-about1.webp'
                type='image/webp'
              />
              <source
                srcSet='/images/custom-ai/ai-about1.png, /images/custom-ai/ai-about1@2x.png'
                type='image/png'
              />
              <img
                src='/images/custom-ai/ai-about1@2x.png'
                alt='Custom AI'
                loading='lazy'
                width='200'
              />
            </picture>
            <h2 className='content-1__content--desktop'>
              Today’s fast-changing world and intense
              <span>
                <br />
                competitive pressure
              </span>{' '}
              are forcing businesses to better build flexibility and agility,
              realize superior sales performance, achieve optimized operations,
              and make informed decisions with real-time insight. Hence,
              businesses are turning to
              <span> leverage technology, including AI solutions.</span>
            </h2>
            <h2 className='content-1__content--mobile'>
              Today’s fast-changing world and intense{' '}
              <span>competitive pressure</span> are forcing businesses to better
              build flexibility and agility, realize superior sales performance,
              achieve optimized operations, and make informed decisions with
              real-time insight. Hence, businesses are turning to
              <span> leverage technology, including AI solutions.</span>
            </h2>
          </div>
          <motion.div {...animation(1)} className='content-2'>
            <h2 className='content-2__content--desktop'>
              We are proud that our tailored end-to-end{' '}
              <span>AI solutions </span>are creating positive business impact
              for the society and <span>overall economy.</span>
            </h2>
            <h2 className='content-2__content--mobile'>
              We are proud that our tailored end-to-end
              <span> AI solutions</span> are creating positive business impact
              for the society and <span>overall economy.</span>
            </h2>
            <picture className='content-2__image'>
              <source
                srcSet='/images/custom-ai/ai-about2.webp'
                type='image/webp'
              />
              <source
                srcSet='/images/custom-ai/ai-about2.png, /images/custom-ai/ai-about2@2x.png'
                type='image/png'
              />
              <img
                src='/images/custom-ai/ai-about2.png'
                alt='Custom AI'
                width='200'
                loading='lazy'
              />
            </picture>
          </motion.div>
        </div>
        <div className='CustomAI__about__container__action'>
          <motion.a
            {...animation(1)}
            href='#contact'
            onClick={(e) => {
              scrollTo(e)
              logEvent({
                ga: {
                  category: 'Custom AI',
                  action: 'Action: Click CTA',
                },
              })
            }}
          >
            Explore Data Wow <IconArrowRight className='icon' />
          </motion.a>
        </div>
      </div>
    </motion.div>
  )
}
