import React from 'react'

import {motion} from 'framer-motion'
import {logEvent} from '@lib/gtm'

export const Hero = ({animation, scrollTo}) => {
  return (
    <section className='CustomAI__hero'>
      <video className='CustomAI__hero__video' autoPlay loop playsInline muted>
        <source src='/videos/custom-ai/hero.mp4' type='video/mp4' />
        Your browser does not support the video tag.
      </video>

      <img
        className='CustomAI__hero__image'
        src='/images/custom-ai/hero_mobile_cropped.png'
        alt='city_view'
      />
      <div className='CustomAI__hero__content'>
        <motion.div {...animation(0.1)}>
          <h1>
            Ignite Your Business with <br />
            <span>Custom AI Solutions</span>
          </h1>
          <p>Meticulously crafted for your unique business needs</p>
        </motion.div>
        <motion.a
          {...animation(0.4)}
          href='#contact'
          onClick={(e) => {
            scrollTo(e)
            logEvent({
              ga: {
                category: 'Custom AI',
                action: 'Action: Click CTA',
              },
            })
          }}
          className='CustomAI__hero__content__button'
        >
          Book a Free Consultation
        </motion.a>
      </div>
    </section>
  )
}
