import React, {useEffect} from 'react'

import {motion, useAnimation} from 'framer-motion'

export const Why = () => {
  const controls = useAnimation()
  useEffect(() => {
    const element = document.querySelector('.CustomAI__why')
    const onScroll = () => {
      const elementTop = element.getBoundingClientRect().top
      const windowHeight = window.innerHeight

      if (elementTop < windowHeight * 0.75) {
        controls.start({opacity: 1, y: 0})
      } else {
        controls.start({opacity: 0, y: 50})
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [controls])
  const data = [
    {
      title: 'Customer-Centric Approach',
      des: 'Commited to continue delivering high quality solutions and exceptional customer support given our Japanese heritage',
      key: 'customer',
    },
    {
      title: 'End-to-End Services',
      des: 'Deploy AI solutions throughout the entire development cycle, including ETL (Extract, Transform, Load), data exploration, proof of concept, model training, and maintenance',
      key: 'end',
    },
    {
      title: 'Tailored AI Solutions',
      des: 'Plan, build, and deliver impactful solutions with our in-depth business understanding and management of diverse data formats',
      key: 'ai',
    },
    {
      title: 'Highly Experienced In-house Tech Experts',
      des: 'Well-versed, experienced, and knowledgeable talents equipped in supporting companies across all AI readiness levels',
      key: 'highly',
    },
    {
      title: 'Fast, Flexible, and Scalable',
      des: 'Gain competitive advantage with agility to cope with rapid changes and resources to scale high volume data projects',
      key: 'fast',
    },
    {
      title: 'Certified Data Security and Privacy Provider',
      des: 'ISO 27001 and ISO 27701 certified ensuring secure and reliable services that safeguard clients’ data security and privacy',
      key: 'certified',
    },
  ]

  return (
    <motion.div
      className='CustomAI__why'
      initial={{opacity: 0, y: 10}}
      animate={controls}
      transition={{duration: 0.1}}
    >
      <div className='CustomAI__why__container'>
        <h2>
          Why Choose
          <span>Data Wow ? </span>
        </h2>
        <div className='CustomAI__why__container__content'>
          {data.map((item) => {
            return (
              <motion.div key={item.key} className='card' id={item.key}>
                <picture className='card__image'>
                  <img
                    className='img'
                    src={`/images/custom-ai/why-${item.key}.svg`}
                    alt={item.title}
                    loading='lazy'
                    width='210'
                    height='160'
                  />
                </picture>
                <div className='card__content'>
                  <h3>{item.title}</h3>
                  <p>{item.des}</p>
                </div>
              </motion.div>
            )
          })}
        </div>
      </div>
      <div className='CustomAI__why__bg'>
        <img
          className='CustomAI__why__bg__image'
          src='/images/custom-ai/why-bg.png'
          alt='why-bg-vector'
        />
      </div>
    </motion.div>
  )
}
