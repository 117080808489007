import axios from 'axios'

import {submitForm} from './submit-form'

export const sendEmail = async ({emailData, reqBody, utm}) => {
  const slackData = {
    path: process.env.SLACK_API_URL,
    text: `${emailData.subject}\n${emailData.message}`,
    webhook_url: process.env.SLACK_WEBHOOK_URL,
  }

  const [{status, data}] = await Promise.all([
    await axios.post('/api/send-noti', emailData),
    emailData.to === process.env.SALES_EMAIL ? await axios.post('/api/send-noti', slackData) : Promise.resolve({status: 200}),
    await submitForm({reqBody, utm}),
  ])
  return {status, data}
}
