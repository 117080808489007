import React, {useEffect, useState} from 'react'
import PropTypes from 'prop-types'

import {motion, useAnimation} from 'framer-motion'

export const Benefits = () => {
  const controls = useAnimation()
  const [open, setOpen] = useState(undefined)
  useEffect(() => {
    const element = document.querySelector('.CustomAI__benefits')
    const onScroll = () => {
      const elementTop = element.getBoundingClientRect().top
      const windowHeight = window.innerHeight

      if (elementTop < windowHeight * 0.75) {
        controls.start({opacity: 1, y: 0})
      } else {
        controls.start({opacity: 0, y: 50})
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [controls])

  const data = [
    {
      title: 'Increase Revenue',
      width: 434,
      content: [
        {
          label: 'Intelligent Pricing Optimization',
          des: 'Optimize pricing strategies in real-time with inputs from market conditions, competitor pricing, and customer behavior',
        },
        {
          label: 'Demand Forecasting',
          des: 'Spot patterns and make reliable forecasts for better decision-making, such as efficient inventory management in anticipation of changes in market movements',
        },
        {
          label: 'Personalized Customer Experiences',
          des: 'Spot patterns and make reliable forecasts for better decision-making, such as efficient inventory management in anticipation of changes in market movements',
        },
      ],
    },
    {
      title: 'Reduce Cost',
      width: 500,
      content: [
        {
          label: 'Automation of Repetitive Tasks',
          des: 'Streamline manual operations to free up human resources and labor costs, increase production efficiency and accuracy, and reduce human errors',
        },
        {
          label: 'Predictive Maintenance',
          des: 'Investigate data from IoT devices to anticipate maintenance or breakdown, ultimately avoiding costly downtime and preventable incidents',
        },
        {
          label: 'Supply Chain Optimization',
          des: 'Monitor demand, inventory patterns, and logistics information to reduce stock-outs, excess inventory, and missed opportunities',
        },
        {
          label: 'Early Fraud Detection',
          des: 'Detect patterns and anomalies that may indicate fraudulent activities, such as payment fraud, phishing attacks, and identify theft',
        },
      ],
    },
    {
      title: 'Data Democratization',
      width: 500,
      content: [
        {
          label: 'Powerful 360° View',
          des: 'Provide all business functions with shared insights on real-time performance and data, driving collaboration and empowering data-driven decisions',
        },
        {
          label: 'Competitive Benchmarking',
          des: 'Monitor competitor movements, identify white spaces, and stay ahead of the rapidly evolving landscape, allowing responsiveness, agility and flexibility',
        },
        {
          label: 'Market Listening',
          des: 'Access large amount of data, such as hot trends, new fads, or shifts on social interests, leverage collective intelligence to stay updated and capitalize on new opportunities',
        },
        {
          label: 'Real-time Decision Making',
          des: 'Analyze, understand, and interpret real-time data across omnichannel platforms, supporting timely and informed decisions',
        },
      ],
    },
  ]
  return (
    <motion.div
      className='CustomAI__benefits'
      initial={{opacity: 0, y: 10}}
      animate={controls}
      transition={{duration: 0.1}}
    >
      <img
        className='CustomAI__benefits__bg'
        src='/images/custom-ai/bg-big.svg'
        alt='custom-ai'
      />
      <div className='CustomAI__benefits__container'>
        <h1>
          Boost business Outcome <br />
          <span>with CustomAI Solutions</span>
        </h1>
        <div className='CustomAI__benefits__container__content'>
          {data.map((item, index) => {
            return (
              <Card
                key={index}
                data={item}
                index={index + 1}
                currentIndex={open}
                onChangeIndex={(index) =>
                  setOpen((open) => {
                    return open === index ? undefined : index
                  })
                }
              ></Card>
            )
          })}
        </div>
      </div>
    </motion.div>
  )
}

const Card = ({data, index, onChangeIndex, currentIndex}) => {
  const formatNo = (num) => {
    return num < 10 ? '0' + num.toString() : num.toString()
  }

  return (
    <motion.div
      className='card'
      initial='beforeClick'
      whileTap='onClick'
      animate={currentIndex === index ? 'open' : ''}
      variants={{
        onClick: {minHeight: `${data.width}px`, height: 'fit-content'},
        open: {
          minHeight: `${data.width}px`,
          height: 'fit-content',
        },
      }}
      onClick={() => onChangeIndex(index)}
    >
      <div className='card__header'>
        <h2>
          <span>{formatNo(index)}</span> {data.title}
        </h2>
        {currentIndex === index ? (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='32'
            height='33'
            viewBox='0 0 32 33'
            fill='none'
          >
            <path
              d='M25.9957 10.9717L15.9997 20.7157L6.00366 10.9717C5.82507 10.7973 5.58532 10.6996 5.33566 10.6996C5.08601 10.6996 4.84626 10.7973 4.66766 10.9717C4.58119 11.0565 4.51249 11.1576 4.46559 11.2692C4.41869 11.3808 4.39453 11.5007 4.39453 11.6217C4.39453 11.7428 4.41869 11.8627 4.46559 11.9743C4.51249 12.0859 4.58119 12.187 4.66766 12.2717L15.3017 22.6397C15.4884 22.8218 15.7389 22.9236 15.9997 22.9236C16.2604 22.9236 16.5109 22.8218 16.6977 22.6397L27.3317 12.2737C27.4188 12.1889 27.488 12.0876 27.5352 11.9756C27.5825 11.8636 27.6068 11.7433 27.6068 11.6217C27.6068 11.5002 27.5825 11.3799 27.5352 11.2679C27.488 11.1559 27.4188 11.0545 27.3317 10.9697C27.1531 10.7953 26.9133 10.6976 26.6637 10.6976C26.414 10.6976 26.1743 10.7953 25.9957 10.9697V10.9717Z'
              fill='black'
            />
          </svg>
        ) : (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='32'
            height='33'
            viewBox='0 0 32 33'
            fill='none'
          >
            <path
              d='M15.2751 11.0801L4.6551 22.2261C4.47781 22.4121 4.37891 22.6592 4.37891 22.9161C4.37891 23.173 4.47781 23.4201 4.6551 23.6061L4.6671 23.6181C4.75304 23.7086 4.8565 23.7806 4.97116 23.8298C5.08583 23.8791 5.20931 23.9044 5.3341 23.9044C5.45888 23.9044 5.58237 23.8791 5.69703 23.8298C5.8117 23.7806 5.91515 23.7086 6.0011 23.6181L16.0011 13.1221L25.9971 23.6181C26.083 23.7086 26.1865 23.7806 26.3012 23.8298C26.4158 23.8791 26.5393 23.9044 26.6641 23.9044C26.7889 23.9044 26.9124 23.8791 27.027 23.8298C27.1417 23.7806 27.2451 23.7086 27.3311 23.6181L27.3431 23.6061C27.5204 23.4201 27.6193 23.173 27.6193 22.9161C27.6193 22.6592 27.5204 22.4121 27.3431 22.2261L16.7231 11.0801C16.6297 10.9821 16.5174 10.904 16.3929 10.8507C16.2685 10.7974 16.1345 10.7699 15.9991 10.7699C15.8637 10.7699 15.7297 10.7974 15.6053 10.8507C15.4808 10.904 15.3685 10.9821 15.2751 11.0801Z'
              fill='black'
            />
          </svg>
        )}
      </div>

      <div key={index} className='card__content'>
        {data.content.map((item, index) => (
          <div key={index}>
            <div className='card__content__heading'>
              <svg
                className='card__content__heading__icon'
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='25'
                viewBox='0 0 24 25'
                fill='none'
              >
                <path
                  d='M2.85547 12.3207H22.8555'
                  stroke='#00A0AC'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M15.7148 5.1781L22.8577 12.321L15.7148 19.4638'
                  stroke='#00A0AC'
                  strokeWidth='2'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </svg>
              <h3>{item.label}</h3>
            </div>
            <p>{item.des}</p>
            {index === data.content.length - 1 ? '' : <hr />}
          </div>
        ))}
      </div>
    </motion.div>
  )
}

Card.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      width: PropTypes.number.isRequired,
      content: PropTypes.array.isRequired,
    })
  ).isRequired,
  index: PropTypes.number.isRequired,
  onChangeIndex: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
}
