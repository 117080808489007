import axios from 'axios'

export async function acceptConsent({apiKey, purposeId, data}) {
  const res = await axios.post(
    process.env.CONSENT_WOW_API_URL,
    {
      first_name: data.name,
      last_name: data.surname,
      email: data.email,
      phone_number: data.phone_number,
      consents: [
        {
          status: 'accepted',
          consent_purpose_id: purposeId,
        },
      ],
    },
    {
      headers: {
        Authorization: apiKey,
      },
    }
  )

  return res.data
}
