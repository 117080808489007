import * as React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import {motion} from 'framer-motion'

function Checkbox({
  isChecked,
  onClick,
  id,
  name,
  value,
  children,
  className,
  style,
  required,
}) {
  return (
    <div
      id={id}
      className={`Component__checkbox ${className}`}
      onClick={onClick}
    >
      <section
        className={classNames('Component__checkbox__input-container', {
          'Component__checkbox__input-container--checked': isChecked,
        })}
      >
        <input
          value={value}
          required={required}
          type='checkbox'
          name={name}
          checked={Boolean(isChecked)}
          onChange={() => {}}
        />
        <svg viewBox='0 0 46 38'>
          <motion.path
            initial={{
              pathLength: isChecked ? 1 : 0,
            }}
            animate={{
              pathLength: isChecked ? 1 : 0,
            }}
            transition={{
              type: 'spring',
              mass: 0.5,
              stiffness: 500,
              damping: 20,
            }}
            d='M5.213,17.548l11.81,11.792l26.549,-26.542'
          />
        </svg>
      </section>
      <section className='Component__checkbox__input-label'>{children}</section>
    </div>
  )
}

Checkbox.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node,
  required: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
  id: PropTypes.string,
}

Checkbox.defaultProps = {
  isChecked: false,
  className: '',
}

export default Checkbox
