import React, {useEffect} from 'react'

import {motion, useAnimation} from 'framer-motion'

export const Iso = () => {
  const controls = useAnimation()
  const ISO = ['pm722313', 'pm744494']

  useEffect(() => {
    const element = document.querySelector('.CustomAI__iso')
    const onScroll = () => {
      const elementTop = element.getBoundingClientRect().top
      const windowHeight = window.innerHeight

      if (elementTop < windowHeight * 0.75) {
        controls.start({opacity: 1, y: 0})
      } else {
        controls.start({opacity: 0, y: 50})
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [controls])

  return (
    <motion.div
      className='CustomAI__iso'
      initial={{opacity: 0, y: 10}}
      animate={controls}
      transition={{duration: 0.1}}
    >
      <p className='desktop'>
        Our top priority is delivering secure and dependable services that
        safeguard <br />
        the <strong>security and privacy</strong> of our valued clients' data.
      </p>
      <p className='mobile'>
        Our top priority is delivering secure and dependable services that
        safeguard the <strong>security and privacy</strong> of our valued
        clients' data.
      </p>
      <div className='CustomAI__iso__image'>
        {ISO.map((item) => (
          <picture key={item}>
            <source
              srcSet={`/images/iso/${item}.png, /images/iso/${item}@2x.png`}
              type='image/png'
            />
            <img
              src={`/images/iso/${item}.png`}
              srcSet={`/images/iso/${item}.png, /images/iso/${item}@2x.png`}
              alt={item}
              width='223px'
              height='auto'
              loading='lazy'
            />
          </picture>
        ))}
      </div>
    </motion.div>
  )
}
