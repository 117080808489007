import React, {useEffect} from 'react'

import {motion, useAnimation} from 'framer-motion'

export const Customer = () => {
  const controls = useAnimation()

  useEffect(() => {
    const element = document.querySelector('.CustomAI__customer')
    const onScroll = () => {
      const elementTop = element.getBoundingClientRect().top
      const windowHeight = window.innerHeight

      if (elementTop < windowHeight * 0.75) {
        controls.start({opacity: 1, y: 0})
      } else {
        controls.start({opacity: 0, y: 50})
      }
    }

    window.addEventListener('scroll', onScroll)
    return () => window.removeEventListener('scroll', onScroll)
  }, [controls])
  const logo = [
    'agoda',
    'visai',
    'scb',
    'zort',
    'kubota',
    'agoda',
    'visai',
    'scb',
    'zort',
    'kubota',
  ]

  return (
    <motion.div className='CustomAI__customer'>
      <div className='CustomAI__customer__container'>
        <h2>
          Trusted by
          <span>
            Domestic <br />
            and International Clients
          </span>
        </h2>

        <div className='CustomAI__customer__container__content'>
          <div
            className='CustomAI__customer__container__content__scroller'
            data-animated='true'
          >
            <div className='logo'>
              {logo.map((item) => (
                <img
                  key={item}
                  src={`/images/custom-ai/clients/${item}.svg`}
                  alt={item}
                  width='160'
                  height='65'
                  loading='lazy'
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  )
}
