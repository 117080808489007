import get from 'lodash/get'
import entries from 'lodash/entries'
import nth from 'lodash/nth'
import replace from 'lodash/replace'
import trim from 'lodash/trim'
import Cookies from 'js-cookie'
import * as __axios from 'axios'

import en from '../public/locales/en.yml'

const locales = {
  en,
}

export const currentLocale = Cookies.get('_datawow_2020_locale') || 'en'

export const currentLocaleSet = (locale) => {
  Cookies.set('_datawow_2020_locale', locale)
}

/*
 * Usage: t('user.title', {name: get(this.props, 'user.data.nickname')})
 */
export const t = (key, args = {}) => {
  try {
    const _key = `${currentLocale}.${key}`

    let text = get(locales, _key, _key)
    const argsEntries = entries(args)

    if (argsEntries.length) {
      text = argsEntries.reduce((result, current) => {
        const pattern = new RegExp(`%{${nth(current, 0)}}`, 'g')

        return result.replace(pattern, nth(current, 1) || '')
      }, text)
    }

    return text
  } catch (e) {
    console.log(e)
  }
}

export const stripTags = (text) => {
  return trim(replace(text, /(<([^>]+)>)/gi, ''))
}

export const _axios = __axios.create({
  baseURL: 'https://backend.datawow.io/api/v1',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Api-Key': 'sNi5amgPA5K3RCv1Amt7Rg',
  },
  responseType: 'json',
})

// We have our own lil' axios because it's easier
// to deal with `authenticity_token` in one-go.
export const axios = {
  get: (url, data) => {
    return _axios.get(url, data)
  },

  post: (url, data, options) => {
    return _axios.post(url, data, options)
  },

  patch: (url, data) => {
    return _axios.patch(url, data)
  },

  put: (url, data) => {
    return _axios.put(url, data)
  },

  delete: (url, data) => {
    return _axios.delete(url, {
      data,
    })
  },
}

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms))

export const isProd = process.env.CIRCLE_BRANCH === 'production'

export const validateEmail = (e) => {
  const email = e.target.value
  const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
  if (!emailPattern.test(email)) {
    e.target.setCustomValidity(
      'Please enter a valid email address (e.g., user@example.com)'
    )
  } else {
    e.target.setCustomValidity('')
  }
}
