import axios from 'axios'

export const submitForm = ({reqBody, utm}) => {
  return axios.post('/api/submit-form', {
    email: reqBody?.email,
    full_name: reqBody?.name,
    phone_number: reqBody?.phone,
    description: reqBody?.message,
    template: reqBody?.template,
    company_name: reqBody?.company,
    subscribe_promotion: reqBody?.allow,
    position: reqBody?.position,
    language: 'en',
    url: reqBody?.url,
    marketing_consent: reqBody?.marketing_consent,
    ...(utm && {
      lead_source: {
        utm_id: utm.id,
        utm_term: utm.term,
        utm_source: utm.source,
        utm_medium: utm.medium,
        utm_campaign: utm.campaign,
        utm_content: utm.content,
      },
    }),
  })
}
