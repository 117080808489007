import React, {useEffect, useState} from 'react'

export const FormInput = ({
  label,
  required,
  type = 'string',
  onChange,
  placeholder,
  name,
  value: defaultValue,
  register,
  error = '',
}) => {
  const [value, setValue] = useState(defaultValue)

  useEffect(() => {
    setValue(defaultValue)
  }, [defaultValue])

  return (
    <div className='input-container'>
      {label && (
        <p>
          {label}
          {required && <span>*</span>}
        </p>
      )}
      <input
        required={required}
        name={name}
        placeholder={placeholder}
        type={type}
        value={value}
        onChange={(e) => {
          setValue(e.target.value)
          onChange?.(e.target.value)
        }}
        {...register?.(name)}
      />
      {error && <p className='error'>{error}</p>}
    </div>
  )
}
